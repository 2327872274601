<template>
  <div class="sb" v-if="item" :class="styleClass">
    <div class="bank_logo">
      <template v-if="category === ECategoryType.Medium">
        <img loading="lazy" :src="'/images/medium/' + (item?.id || 7) + '.svg'" class="lg" alt="">
      </template>
      <template v-else>
        <!-- <img loading="lazy" v-if="!item.item_brand?.logo_s " src="/images/setup_diary/setup_diary_icons-18.svg" alt=""> -->
        <img :src="item.item_brand?.logo_s" data-not-lazy alt="">
      </template>
    </div>

    <div class="action">
      <div @click="clone" class="btn_clone" v-if="category === ECategoryType.Lamp">
        <i class="icon-clone"/>
      </div>

      <div @click="remove" class="btn_trash">
        <i class="icon-remove"/>
      </div>
    </div>

    <div class="names" v-if="category !== ECategoryType.Medium">
      <div class="name" v-if="category == ECategoryType.Lamp">
        {{ item.props.type ?  $t($constants.typeLightShort[item.props.type]) : '' }} 
        {{ item.props.power && item.props.power ? '/' : '' }}
        {{ item.props.power ? item.props.power + 'W' : '' }}
      </div>
      <div class="name" v-else="ECategoryType.Lamp">{{ item.name }}</div>

      <div class="sec_name">
        <template v-if="!item.item_brand?.id">
          {{ $t("diary_info_custom_brand_box_name") }}
        </template>
        <template v-else>
          {{ item.item_brand.id < 1 ? $t("diary_info_custom_brand_box_name") : item.item_brand.name }}
        </template>
      </div>
    </div>

    <template v-if="category === ECategoryType.Medium">
      <div class="names">
        <div class="sec_name">{{ item.name }}</div>
      </div>

      <div class="cnt">
        <div class="mn" @click="counterMedium(-1)">-</div>

        <div class="cn">{{ item.value }}</div>

        <div class="pl" @click="counterMedium(1)">+</div>
      </div>

      <div class="sld">
        <input type="range" class="range" max="100" min="0" @change="changeMedium" v-model="item.value">
      </div>
    </template>

    <template v-if="category === ECategoryType.Seed">
      <div class="cnt">
        <div class="mn" @click="setCounter(-1)">-</div>

        <div class="cn">{{ item?.props?.cnt }}</div>

        <div class="pl" @click="setCounter(1)">+</div>
      </div>

      <div class="types">
        <div @click="changeSeedType(1)" :class="'type' + (Number(item?.props?.type) === 1 ? ' active ' : '')">
          {{ $t("universal_type_seed_seeds") }}
        </div>

        <div @click="changeSeedType(2)" :class="'type' + (Number(item?.props?.type) === 2 ? ' active ' : '')">
          {{ $t("universal_type_seed_clones") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">

import { debounce } from 'lodash'
import { ECategoryType } from "~/types/Enums"


const props = defineProps({
  item: {
    type: Object
  },
  category: {
    type: String
  },
  totalMedium: {
    type: Number,
    required: false,
    default: 0
  },
  cnt: {
    type: Object,
    required: false,
    default: null
  }  
})

const emit = defineEmits(['remove', 'clone'])

const remove = () => {
  emit('remove')
}

const clone = () => {
  emit('clone', props.item)
}

const styleClass = computed(() => {
  return props.category ? 'sb_' + props.category : ''
})

const changeSeedType = (vl: any) => {
  props.item.props.type = vl
}

const setCounter = (vl: number) => {
  if (Number(props.item.props.cnt) + vl > 0) props.item.props.cnt = Number(props.item.props.cnt) + vl
}

const changeMedium = () => {
  let result = parseInt(props.item.value)

  if (props.totalMedium as number > 100) {
    props.item.value = 100 - (props.totalMedium as number - result)
  }
}

const counterMedium = (vl: number) => {
  let result = parseInt(props.item.value)

  if (props.totalMedium as number >= 100 && vl > 0) return

  if (result + vl > 0) {
    props.item.value = result + vl
  }

  changeMedium()
}
</script>

<style scoped lang="scss">
.sb {
  align-items: center;
  background-color: var(--un-background-color-gray);
  border-bottom: 2px solid var(--un-primary-back-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 190px;
  padding: 1rem;
  position: relative;
  width: 170px;

  .action {
    position: absolute;
    right: 5px;
    top: -5px;
    width: 24px;

    .btn_clone {
      align-items: center;
      background-color: #cecece !important;
      border-radius: 30px;
      color: #666 !important;
      cursor: pointer;
      display: flex;
      height: 25px;
      justify-content: center;
      position: absolute;
      right: 20px;
      width: 26px;
    }

    .btn_trash {
      align-items: center;
      background-color: #db2828 !important;
      border-color: #db2828 !important;
      border-radius: 30px;
      color: #fff !important;
      cursor: pointer;
      display: flex;
      height: 25px;
      justify-content: center;
      position: absolute;
      right: -10px;
      width: 26px;
    }
  }

  .bank_logo {
    align-items: center;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 80%;

    img {
      aspect-ratio: 1/1;
      object-fit: contain;
      width: 100%;
    }
  }

  .names {
    width: 100%;

    .name {
      display: block;
      font-weight: 700;
      min-height: 1.4em;
      text-align: center;
      width: 100%;
    }

    .sec_name {
      color: gray;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.sb_seed {
    justify-content: center;
    max-width: 100%;
    min-height: 220px;
    padding: 1rem 1rem 0;

    &:not(.created) {
      cursor: pointer;
    }

    .bank_logo {
      width: 80%;
      padding: 5px;
      aspect-ratio: 1/1;

      .lg {
        width: 100%;
      }

      .icplus {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        justify-content: center;
      }
    }

    .cnt {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: .5rem;

      .mn {
        align-items: flex-start;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        font-size: 15px;
        font-weight: 700;
        height: 24px;
        justify-content: center;
        line-height: 22px;
        width: 24px;
      }

      .pl {
        align-items: flex-start;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        font-size: 15px;
        font-weight: 700;
        height: 24px;
        justify-content: center;
        line-height: 22px;
        width: 24px;
      }

      .cn {
        font-weight: 700;
        margin: 0 .5rem;
      }
    }

    .names {
      width: 100%;

      .name {
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 100%;
      }

      .sec_name {
        color: gray;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .types {
      background-color: var(--un-primary-back-color);
      border-radius: 0 0 19px 19px;
      border-top: 1px #fff solid;
      box-shadow: inset 0 4px 10px #0000000f;
      display: flex;
      margin: .5rem 0 0;
      width: calc(100% + 2rem);

      .type {
        color: #ffffff94;
        cursor: pointer;
        font-weight: 700;
        padding: 10px;
        text-align: center;
        width: 50%;

        &:first-child {
          border-radius: 0 0 0 19px;
          padding: 10px 5px 10px 15px;
        }

        &:last-child {
          border-radius: 0 0 19px 0;
          padding: 10px 15px 10px 5px;
        }

        &:hover,
        &.active {
          color: #fff;
        }
      }
    }

    @container pb (max-width: 600px) {
      min-height: auto;
      width: 100%;
      flex-direction: row;
      margin-right: 0;
      border-radius: 10px;
      background-color: var(--un-background-color-gray);
      flex-wrap: wrap;

      .bank_logo {
        height: 40px;
        margin-right: 10px;
        overflow: hidden;
        width: 40px;
      }

      .cnt {
        width: 80px;
        margin-top: 0;
      }

      .names {
        text-align: left;
        justify-content: left;
        margin-right: auto;
        width: calc(100% - 150px);

        .name {
          text-align: left;
        }

        .sec_name {
          text-align: left;
        }
      }

      .edit_diary {
        .new_strain {
          width: 100%;
        }
      }

      .types {
        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-right: -1rem;
        border-radius: 0 0 10px 10px;
        min-height: 40px;

        .type {
          &:first-child {
            align-items: center;
            border-radius: 0 0 0 19px;
            display: flex;
            justify-content: center;
            padding: 5px 5px 5px 15px;
          }

          &:last-child {
            align-items: center;
            border-radius: 0 0 19px;
            display: flex;
            justify-content: center;
            padding: 5px 15px 5px 5px;
          }
        }
      }
    }
  }

  &.sb_medium {
    .cnt {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: .5rem;

      .mn {
        align-items: flex-start;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        font-size: 15px;
        font-weight: bold;
        height: 24px;
        justify-content: center;
        line-height: 22px;
        width: 24px;
      }
      .pl {
        align-items: flex-start;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        font-size: 15px;
        font-weight: bold;
        height: 24px;
        justify-content: center;
        line-height: 22px;
        width: 24px;
      }

      .cn {
        margin: 0 .5rem;
        font-weight: 700;
      }
    }

    .sld {
      bottom: -16px;
      left: 1rem;
      position: absolute;
      width: calc(100% - 2rem);
      z-index: 3;

      input[type="range"] {
        -webkit-appearance: none;
        background-color: transparent;
        height: 20px;
        width: 100%;
      }
      input[type="range"]:focus {
        outline: none;
      }
      input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: var(--un-primary-back-color);
        border-radius: 5px;
      }
      input[type="range"]::-webkit-slider-thumb {
        box-shadow: 0 0 5px #00000042;
        border: 1px solid #cecece;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -9px;
      }
      input[type="range"]:focus::-webkit-slider-runnable-track {
        background: var(--un-primary-back-color);
      }
      input[type="range"]::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: var(--un-primary-back-color);
        border-radius: 1.3px;
        border: 0;
      }
      input[type="range"]::-moz-range-thumb {
        box-shadow: 0 0 5px #00000042;
        border: 1px solid #cecece;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -9px;
      }
      input[type="range"]::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: var(--un-primary-back-color);
        border-radius: 5px;
      }
      input[type="range"]::-ms-fill-lower {
        background: var(--un-primary-back-color);
        border-radius: 2.6px;
      }
      input[type="range"]::-ms-fill-upper {
        background: var(--un-primary-back-color);
        border-radius: 10px;
      }
      input[type="range"]::-ms-thumb {
        border: 1px solid #cecece;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #fff;
        cursor: pointer;
      }
      input[type="range"]:focus::-ms-fill-lower {
        background: var(--un-primary-back-color);
      }
      input[type="range"]:focus::-ms-fill-upper {
        background: var(--un-primary-back-color);
      }
    }
  }

  @container pb (max-width: 600px) {
    border-radius: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0;
    min-height: auto;
    width: 100%;

    .action {
      position: absolute;
      right: 0;
      top: 0;
    }

    .bank_logo {
      height: 40px;
      margin-right: 10px;
      overflow: hidden;
      width: 40px;
    }

    .names {
      justify-content: left;
      margin-right: auto;
      text-align: left;
      width: calc(100% - 150px);

      .name {
        text-align: left;
      }

      .sec_name {
        text-align: left;
      }
    }
  }
}

.edit_diary {
  .new_strain {
    .sb {
      .types {
        pointer-events: none;
      }
    }
  }

  @media (max-width: 600px) {
    .edit_diary {
      .new_strain {
        .sb {
          .bank_logo {
            height: 40px;
            overflow: hidden;

            img {
              margin-left: -14px;
              margin-top: -15px;
              width: 59px;
            }
          }
        }
      }
    }
  }
}
</style>